import axios from "axios";

//const apiUrl = "http://127.0.0.1:1337";
//const apiUrl = "https://rmobility.ovh";
//const apiUrl = "http://192.168.1.105:1337";
const apiUrl = "https://rmobility.ovh";
//const apiUrl = "https://rmobilitytest.raynis.co";

export async function apiRequest(
  method, // "GET" | "DELETE" | "HEAD" | "OPTIONS" | "POST" | "PUT" | "PATCH" | "LINK" | "UNLINK" | undefined,
  endPoint, // string,
  variables, // any,
  formData //boolean
) {
  let authToken;
  let user = JSON.parse(localStorage.getItem('user'));
  authToken = user.tokenSalt;

  try {
    const options = {
      method,
      url: `${apiUrl}/${endPoint}`,
      data: variables,
      headers: {}
    };

    if (authToken) options.headers["authorization"] = `Bearer ${authToken}`;
    if (formData) options.headers["Content-Type"] = "application/x-www-form-urlencoded";

    const resp = await axios(options);
    return resp;
  } catch (err) {
    // Modification de la gestion des erreurs
    console.error("[ERROR]:", err);
    
    // Si nous avons une réponse du serveur
    if (err.response) {
      console.log("Response data:", err.response.data);  // Log de la réponse pour plus de détails
      // Pour l'erreur 401 (non autorisé)
      if (err.response.status === 401) {
        localStorage.removeItem("accessToken");
        return err.response;
      }
      
      // Pour les autres erreurs (y compris 409)
      throw {
        status: err.response.status,
        data: err.response.data,
        message: err.response.data.message || "Une erreur est survenue"
      };
    }
    
    // Pour les erreurs sans réponse du serveur
    throw {
      status: 500,
      data: null,
      message: "Erreur de connexion au serveur"
    };
  }
}
import firebase from "firebase/app";
//import { fr } from 'date-fns/locale'
require("firebase/firestore");
const firebaseConfig = {
  apiKey: "AIzaSyAVoPK2FpE6cOVshGuiYg58Glhz8XLyG4I",
  authDomain: "rmobility2020-46bea.firebaseapp.com",
  projectId: "rmobility2020-46bea",
  databaseURL: "https://rmobility2020-46bea.firebaseio.com",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db=firebaseApp.firestore()
export default db
// Required for side-effects

<script>
import i18n from "../i18n";
import { required } from "vuelidate/lib/validators";
import simplebar from "simplebar-vue";
import db from "@/firebase/init.js";
import { formatDistance } from "date-fns";
import router from "@/router/index.js";
import { apiRequest } from "@/helpers/api-call";

export default {
  components: { simplebar },

  data() {
    return {
      checked: false,
      languages: [
        {
          language: "fr",
          title: "French",
        },
      ],
      showdisable: false,
      current_language: "fr",
      current_user: JSON.parse(localStorage.getItem("user")),
      current_profile:
        JSON.parse(localStorage.getItem("user")).userAvatarCode != null
          ? "https://rmobility.ovh/admin/image/?codeImage=" +
            JSON.parse(localStorage.getItem("user")).userAvatarCode +
            "&token=" +
            JSON.parse(localStorage.getItem("user")).tokenUser
          : require("@/assets/images/logo.png"),

      submitted: false,

      chat: {
        name: "RMobility",
        sender: "admin",
        message: " ",
        type: false,
      },

      ChatData: [
        {
          id: 1,
          name: "Frank Vickery",
          message: "Hey! I am available",
          image: require("@/assets/images/logo.png"),
          time: "12:09",
        },
        {
          id: 2,
          align: "right",
          name: "Ricky Clark",
          message: "Hi, How are you? What about our next meeting?",
          time: "10:02",
        },
        {
          text: "Today",
        },
        {
          id: 3,
          name: "Frank Vickery",
          message: "Hello!",
          image: require("@/assets/images/logo.png"),
          time: "10:06",
        },
        {
          id: 4,
          name: "Frank Vickery",
          message: "& Next meeting tomorrow 10.00AM",
          image: require("@/assets/images/logo.png"),
          time: "10:06",
        },
        {
          id: 5,
          align: "right",
          name: "Ricky Clark",
          message: "Wow that's great",
          time: "10:07",
        },
      ],
      online: false,
      discussions: [],
      db: db,
      conversations: [],
      nom: "",
      prenom: "",
      idDocument: "",
      username: "",
      identifiantDiscussion: "",
      totalChat: 0,
      idChat: null,
      modalMessage: false,
      totalMassage: 0,
      idSender: 0,
      searchKey: "",
      userProfile: null,
      niveauUser:[],
 
      
      idCLient: null,
      showDeleteChat: false,
      idToDelete: null,
      totalNotif:0,
      actualUserId:false
    };
  },

  created() {
    this.db.collection("chat_bot").onSnapshot((snap) => {

     console.log(snap);

      

    this.getChatRoom();

  })
},
  //components: { simplebar },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },

    formSubmit(e) {
      this.submitted = true;
      console.log(e);
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const id = this.ChatData.length;
        const message = this.chat.message;
        const currentDate = new Date();
        this.ChatData.push({
          id: id + 1,
          align: "right",
          name: "Ricky Clark",
          message,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
        this.handleScroll();
      }
      this.submitted = false;
      this.chat = {};
    },

    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async getChatRoom() {

     
      this.db
        .collection("chat_bot")
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot.docs);
          querySnapshot.docs.forEach((element) => {
            this.identifiantDiscussion = element.id;
            this.db
              .collection("chat_bot")
              .doc(element.id)
              .collection("messages")
              .orderBy("date", "desc")
          
              .onSnapshot(async (message) => {
                //console.log(message.docs[0].data());
                console.log("message", message)
                if(message.docs[0].data()){
                  const msg = message.docs[0].data();
                

                const objIndex = this.discussions.findIndex(
                  (obj) => obj.id == element.id
                );
                console.log("voici la valeur de l'index ", objIndex);

                if (objIndex < 0 && msg.sender=='user') {
                  const data = await apiRequest(
                    "POST",
                    "admin/detail-user",
                    { id: element.data().client.idSender },
                    false
                  );
                  if (data && data.data) {
                    this.userProfile = data.data.userAvatarCode;
                  }

                  this.online = element.data().client.online;

                  this.discussions.push({
                    id: element.id,
                    totalMessage: message.docs.length,

                    firstname: element.data().client.firstname,
                    lastname: element.data().client.lastname,
                    idSender: element.data().client.idSender,
                    online: element.data().client.online,
                    for_admin: msg.for_admin,
                    message: msg.text,
                    image:
                      this.userProfile != null
                        ? `https://rmobility.ovh/admin/image/?codeImage=` +
                          this.userProfile +
                          `&token=` +
                          this.tokenUser
                        : "https://cdn-icons-png.flaticon.com/512/1144/1144760.png",
                    date: formatDistance(
                      new Date(msg.date.toDate()),
                      new Date(),
                      { addSuffix: true },
                      { locale: "fr_FR" }
                    ),
                  });
                  
                } else {
                  let admin = false;
                  this.discussions[objIndex].message = msg.text;
                  this.discussions[objIndex].totalMessage = message.docs.length;
                  this.discussions[objIndex].for_admin = msg.for_admin;
                  this.discussions[objIndex].online =
                    element.data().client.online;
                  this.online = this.discussions[objIndex].online;
                  admin = this.discussions[objIndex];

                  if (admin.for_admin === true) {
                    this.showDiscussion(
                      this.discussions[objIndex].id,
                      this.discussions[objIndex].firstname,
                      this.discussions[objIndex].lastname,
                      this.discussions[objIndex].idSender,
                      this.online
                    );
                  }
                }

                }
                
                this.totalChat = this.discussions.length;
              });
          });
        }
        
      
        
        );


    },

    async showDiscussion(id, nom, prenom, idSender, online) {
      console.log(online);
      this.conversations = [];
      this.idChat = id;
      this.idCLient = idSender;

      this.nom = nom;

      this.prenom = prenom;
      this.username = nom + " " + prenom;
      this.db
        .collection("chat_bot")
        .doc(id)
        .collection("messages")

        .orderBy("date", "asc")
        .onSnapshot((snap) => {
          snap.docs.forEach((element) => {
            let data = element.data();
            this.conversations.push({
              name: data.name,
              for_admin: data.for_admin,
              sender: data.sender,
              message: data.text,
              archived: data.archived,
              date: formatDistance(
                new Date(data.date.toDate()),
                new Date(),
                { addSuffix: true },
                { locale: "fr_FR" }
              ),
            });
            this.totalMassage = this.conversations.length;
          });
      
          this.modalMessage = true;
          if (this.modalMessage == true) {
            setTimeout(() => {
              this.$refs.current.SimpleBar.getScrollElement().scrollTop =
                this.$refs.current.SimpleBar.getScrollElement().scrollHeight +
                1500;
            }, 500);
          }
    
       
        });
    },

    sendMessage() {
      this.db
        .collection("chat_bot")
        .doc(this.idChat)
        .collection("messages")
        .add({
          date: new Date(),
          name: this.chat.name,
          sender: this.chat.sender,
          text: this.chat.message,
          type: this.chat.type,
          receiver: this.idCLient,
          seen: false,
          archived: false,
        })
        .then((response) => {
          console.log(response);
          this.chat.message = "";
          this.handleScroll();
        });
    },

    scrollable() {
      var container2 = document.querySelector(
        "#containerElement .simplebar-content-wrapper"
      );
      container2.scrollTo({ top: 500, behavior: "smooth" });
    },

    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
            this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },

    goToDetail(row) {
      router.push(`/user/user_info/${row}`);
    },
    confirmDeleteBtn(idDiscuss) {

      setTimeout(() => {
        this.modalMessage=false;
      this.showDeleteChat = true;
      this.idToDelete = idDiscuss;
      }, 1000);
     
    },

    notConfirmDeleteBtn() {
      this.modalMessage=false;
      this.showDeleteChat = false;
      this.idToDelete = null;
    },

    onDelete() {
      console.log("l'id sélectionné est ",       this.idToDelete);
      this.db
        .collection("chat_bot")
        .doc(this.idToDelete)
        .collection("messages")
        .get()
        .then((element) => {
          element.docs.forEach((element) => {
            element.ref.delete();
          });
          this.db.collection("chat_bot").doc(this.idToDelete).delete();
          setTimeout(() => {
            console.log("chat supprimé");
        
            this.$toast.success("Discussions supprimée avec succès", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
            this.discussions=[]
      this.getChatRoom();

            this.idToDelete="";
   this.modalMessage=false;
      this.showDeleteChat = false;
          }, 2000);
        });
       
  
      },

  },

  mounted() {
    console.log("user level", this.niveauUser);
 
  },
  computed: {
    searchResult() {
      let messagesList = this.conversations;

      if (this.searchKey != "" && this.searchKey) {
        messagesList = messagesList.filter((item) => {
          return item.message
            ?.toUpperCase()
            .includes(this.searchKey?.toUpperCase());
        });
      }

      return messagesList;
    },
    /*    filterUserByName: function () {
      return this.users.filter(
        (utilisateur) =>
          !utilisateur.firstname
            .toLowerCase()
            .indexOf(this.seachKey.toLowerCase().trim()),
      )
    },
    https://stackoverflow.com/questions/1789945/how-to-check-whether-a-string-contains-a-substring-in-javascript
    
    searchresultÅÅ

     searchResult() {
  let tempRecipes = this.recipes
  
  if (this.searchValue != '' && this.searchValue) {
        tempRecipes = tempRecipes.filter((item) => {
          return item.title
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        })
      }
    return tempRecipes
  }c
    */
  },
  validations: {
    chat: {
      message: { required },
    },
  },
  watch: {
    checked(newValue, oldValue) {
      console.log(oldValue);

      if (newValue === true) {
        window.location.href = "/logout";
      }
    },
    totalMassage(newValue, oldValue) {
      console.log(oldValue);
      if (newValue) {
        this.totalMassage = newValue;
      }
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="#" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/rmolog.png" alt height="30" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/rmolog.png" alt height="30" />
            </span>
          </a>

          <a href="#" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/rmolog.png" alt height="30" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/rmolog.png" alt height="30" />
            </span>
          </a>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
        <!--<form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="ri-search-line"></span>
          </div>
        </form>-->
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>
        <div
          class="d-flex align-items-center checker"
          v-b-tooltip.hover.v-danger
          title="SE DÉCONNECTER"
        >
          <input type="checkbox" v-model="checked" />
        </div>

        <!--<b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="ri-notification-3-line"></i>
            <span class="noti-dot"></span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px;">
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.order.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.order.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.order.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.james.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.james.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.james.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.item.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.item.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.item.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-4.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.salena.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.salena.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.salena.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-link font-size-14 btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle mr-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown>-->

        <!--  <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="`${current_profile}`"
              alt=""
            />
            <span class="d-none d-xl-inline-block ml-1">{{
              current_user.firstname
            }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
       
          <a class="dropdown-item" href="#">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t("navbar.dropdown.kevin.list.profile") }}
          </a>
      <a class="dropdown-item" href="/auth/lock-screen-1">
            <i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.lockscreen') }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.kevin.list.logout") }}
          </a>
        </b-dropdown> -->
        <div
          class="p-2 border-top"
          v-if="!(niveauUser[0] == 15 && niveauUser.length == 1)"
        >
          <a
            class="btn btn-sm btn-link font-size-14 mt-2 d-flex align-items-center text-center ourLink"
            href="javascript:void(0)"
            v-b-toggle.sidebar-right
          >
            <img
              src="@/assets/images/bubble-chat.png"
              alt="noitf"
              width="28"
              height="28"
              class="image-fluid"
            />
            <div v-if="totalChat != 0" class="badge-with-number">
              {{ totalChat > 9 ? "9+" : totalChat }}
            </div>
          </a>
        </div>
      </div>
      <b-sidebar id="sidebar-right" title="Discussions" right shadow backdrop>
        <div class="py-2">
          <div class="chat-user-panel">
            <ul
              class="chat-list"
              v-for="(discuss, index) in discussions"
              :key="index"
            >
              <li class="listDiscuss">
                <a
                  id="show-btn"
                  v-on:click.prevent="
                    showDiscussion(
                      discuss.id,
                      discuss.firstname,
                      discuss.lastname,
                      discuss.idSender,
                      discuss.online
                    )
                  "
                >
                  <ul class="chat-detail">
                    <li>
                      <img
                        :src="discuss.image"
                        alt="img"
                        class="chat-profile-user"
                      />
                      <!-- <div
                        class="chat-profile-user"
                        

v-bind:style="{ backgroundImage: 'url(' + discuss.image + ') height: 500px' }"
                      ></div> -->
                    </li>
                    <li>
                      <div class="chat-user-nm">
                        {{ discuss.lastname }} {{ discuss.firstname }}
                      </div>
                      <div class="chat-desc">{{ discuss.message }}</div>
                    </li>
                    <li>
                      <div class="chat-seen" >
                        {{ discuss.date.split("ago")[0].split("about")[1] }}
                        <button
                          class="delete"
                          @click="confirmDeleteBtn(discuss.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                      <div class="not-read-lable">
                        {{
                          discuss.totalMessage > 9 ? "9+" : discuss.totalMessage
                        }}
                      </div>
                    </li>
                  </ul>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </b-sidebar>
    </div>
    <div>
      <b-modal
        ref="my-modal"
        v-model="modalMessage"
        hide-footer
        title="Discussions"
      >
        <div class="user-chat-border">
          <div class="row">
            <div class="col-md-5 col-9">
              <h5 class="font-size-15 mb-1">{{ username }}</h5>
              <p class="text-muted mb-0" v-if="online === true">
                <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                en ligne
              </p>
              <p class="text-muted mb-0" v-if="online === false">
                <i class="mdi mdi-circle text-danger align-middle mr-1"></i>
                hors ligne
              </p>
            </div>
            <div class="col-md-7 col-3">
              <ul class="list-inline user-chat-nav text-right mb-0">
                <li class="list-inline-item">
                  <b-nav-item-dropdown
                    toggle-class="nav-btn"
                    right
                    variant="black"
                    menu-class="dropdown-menu-md p-0"
                  >
                    <template v-slot:button-content>
                      <i class="mdi mdi-magnify"></i>
                    </template>
                    <form class="p-2">
                      <div class="search-box">
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control rounded bg-light border-0"
                            placeholder="Rechercher..."
                            v-model="searchKey"
                          />
                          <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </form>
                  </b-nav-item-dropdown>
                </li>
                <li class="list-inline-item d-none d-sm-inline-block">
                  <a
                    class="dropdown-item link-profile"
                    href="#"
                    @click.stop.prevent="goToDetail(idCLient)"
                  >
                    <i class="mdi mdi-account"></i
                  ></a>

                  <!--  <a class="dropdown-item" href="#">Clear chat</a>
                    <a class="dropdown-item" href="#">Muted</a>
                    <a class="dropdown-item" href="#">Delete</a> -->
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="chat-widget">
            <div class="chat-conversation">
              <simplebar
                style="max-height: 292px"
                ref="current"
                id="containerElement"
              >
                <ul
                  class="list-unstyled mb-0 pr-3"
                  v-for="(data, index) in searchResult"
                  :key="index"
                  :class="{
                    right:
                      `${data.sender}` == 'robot' ||
                      `${data.sender}` == 'admin',
                  }"
                >
                  <li v-if="!data.text">
                    <div class="conversation-list">
                      <div v-if="data.image" class="chat-avatar">
                        <img :src="`${data.image}`" alt />
                      </div>
                      <div class="ctext-wrap">
                        <div class="conversation-name"></div>
                        <div class="ctext-wrap-content">
                          <p class="mb-0">{{ data.message }}</p>
                        </div>
                        <p class="chat-time mb-0">
                          <i
                            class="mdi mdi-clock-outline align-middle mr-1"
                          ></i>
                          {{ data.date }}
                          <i
                            class="fa fa-archive"
                            aria-hidden="true"
                            v-if="data.archived === true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </li>

                  <li v-if="data.text">
                    <div class="chat-day-title">
                      <span class="title">Today</span>
                    </div>
                  </li>
                </ul>
              </simplebar>
            </div>
          </div>
        </div>
        <div class="p-3 chat-input-section border-top">
          <form @submit.prevent="sendMessage" class="row">
            <div class="col">
              <div>
                <input
                  type="text"
                  class="form-control rounded chat-input pl-3"
                  placeholder="Enter Message..."
                  v-model="chat.message"
                  :class="{ 'is-invalid': submitted && $v.chat.message.$error }"
                />
                <div
                  v-if="submitted && $v.chat.message.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.chat.message.required"
                    >This value is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button
                :disabled="$v.chat.$invalid"
                type="submit"
                class="btn btn-primary chat-send w-md waves-effect waves-light"
              >
                <span class="d-none d-sm-inline-block mr-2">Envoyer</span>
                <i class="mdi mdi-send"></i>
              </button>
            </div>
          </form>
        </div>
      </b-modal>
      <b-modal
        id="modal-old"
        centered
        v-model="showDeleteChat"
        title="Voulez-vous vraiment supprimer cette discussion ?"
        title-class="font-18"
        hide-footer
      >
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="notConfirmDeleteBtn()"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="onDelete()"
            >Oui</b-button
          >
        </div>
      </b-modal>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>

<style>
.checker {
  background: #fff;
  outline: none;
}
.checker input[type="checkbox"] {
  height: 40px;
  width: 40px;
  -webkit-appearance: none;
  box-shadow: -6px -6px 8px rgba(255, 255, 255, 0.9),
    10px 10px 15px rgba(70, 70, 70, 0.12);
  margin: auto 5px;
  border-radius: 50%;
  border: 3px solid #fff;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checker input[type="checkbox"]:hover:after {
  color: red;
}
.checker input[type="checkbox"]:after {
  font-family: FontAwesome;
  content: "\f011";
  color: #7a7a7a;
  font-size: 25px;
}
.checker input[type="checkbox"]:checked {
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12),
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(70, 70, 70, 0.12);
}

.checker input[type="checkbox"]:checked:after {
  color: rgb(0, 0, 156);
}

.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  transform: translateX(0%) translateZ(1px) !important;

  width: 20%;
  height: 647px;
  max-height: 100%;
  min-width: 380px;
  margin: 0;
  outline: 0;
  min-height: 400px;
  background: #fff !important;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2) !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

/*** chat user session */
.chat-detail li {
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
}
.chat-list .chat-detail li:last-child {
  text-align: right;
  float: right;
}
.chat-user-nm {
  color: #2a2a2a;
}

.chat-desc {
  text-overflow: ellipsis;
  width: 13rem;
  white-space: nowrap;
  overflow: hidden;
}
.chat-desc,
.chat-seen {
  color: #808080;
  z-index: 1000px;
}

ul {
  list-style-type: none;
  list-style: none;
  padding-left: 0;
}
.chat-profile-user {
  margin-right: 10px;
  height: 50px;
  width: 50px;
  border: 2px solid #b0b0b0;
  border-radius: 50px;
}
.chat-user-nm {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}
.chat-list {
  max-height: calc(100vh - 307px);
  overflow-y: auto;
}
.chat-list::-webkit-scrollbar-thumb {
  background: #1d437e;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.chat-list::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.chat-list::-moz-scrollbar-thumb {
  background: #1d437e;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.chat-list::-moz-scrollbar {
  height: 6px;
  width: 6px;
}
.chat-list li a {
  padding: 20px 10px;
  display: block;
  box-sizing: border-box;
}
.chat-list li a:hover {
  background: #f1f2f5;
}

.chat-user-panel {
  width: auto;
  background: #fff;
  border-radius: 5px;
  padding: 0.5rem;
}

.count-no {
  padding-right: 10px;
  color: #2a2a2a;
}
.not-read-lable {
  background: #e12026;
  font-size: 12px;
  color: #ffffff;
  height: auto;
  width: auto;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

/** Chat texxt color */

.chat-conversation .conversation-list .ctext-wrap-content {
  background-color: rgb(59, 130, 246, 1);
  position: relative;
}

.badge-with-number {
  position: absolute;
  color: #fff;
  background-color: #f5424e;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 10px;
  top: -8px;
  right: -5px;
}

.ourLink {
  position: relative;
}

.fa-archive {
  color: #f5424e;
}

.link-profile {
  font-size: 1.5rem;
}

.delete {
  z-index: 800px;
  cursor: not-allowed;
  border: none;
  outline: none;
  padding: 0;
}
</style>
